import React from "react";
import { Row, Col } from "antd";
const Footer = () => {
  return (
    <>
      <footer id="site-footer">
        <div className="footer-bottom">
          <Row justify="left">
            <Col md={14} sm={13}>
              <Row justify="left">
                <small>© 2024 Value Analytics Labs | All rights reserved</small>{" "}
                <br />
                <br />
              </Row>
              <Row justify="left">
                <Col md={2} sm={2} className="footer-bottom-subtitle">
                  <small>
                    <b>Funding:</b>
                  </small>
                </Col>
                <Col md={9} sm={9}>
                  <small>
                    Funding provided by Seagen Inc., which was acquired by
                    Pfizer Inc. in Dec. 2023.
                  </small>
                  <br />
                </Col>
                <small></small>
              </Row>
            </Col>

            <Col md={10} sm={13}>
              <Row justify="left">
                <small></small> <br />
                <br />
              </Row>
              <Row justify="left">
                <Col md={2} sm={2} className="footer-bottom-subtitle">
                  <small>
                    <b>Contact:</b>
                  </small>
                </Col>
                <Col md={9} sm={9}>
                  <small>info@valueanalyticslabs.com</small>
                  <br />
                </Col>
                <small></small>
              </Row>
              {/* <Row justify="left">
                <Col md={3} sm={2} className="footer-bottom-subtitle">
                  <small>
                    <br />
                    <br />
                    <b>Contact:</b> <br />
                  </small>
                </Col>
                <Col md={18} sm={9}>
                  <small>
                    <br />
                    <br />
                    info@valueanalyticslabs.com
                  </small>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};

export default Footer;
