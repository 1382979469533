import { useMapEvents } from "react-leaflet";

export default function MapStateHandler({ setZoom, setCenter }) {
  // return null; // delete here if you want to use this component for the map state handler
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
      setCenter(mapEvents.getCenter());
    },
    dragend: () => {
      setZoom(mapEvents.getZoom());
      setCenter(mapEvents.getCenter());
    },
  });

  return null;
}
