import React, { useState } from "react";
import "../App.css";

import Footer from "./Footer";
import HeaderNew from "./HeaderNew";
import Filters from "./Filters";
import Map from "./Map";

function OnlineTool() {
  const [year, setYear] = useState("2020");
  const [ageGroup, setAgeGroup] = useState("All");
  const [denominatorFilter, setDenominatorFilter] = useState(4);
  const [insuranceFilter, setInsuranceFilter] = useState("All");
  const [reportFilter, setReportFilter] = useState("r/mCC Rates");
  const [showCenters, setShowCenters] = useState(true);

  const returnValue = (
    <div>
      <HeaderNew selected="onlineTool" />

      <Filters
        // setYear={setYear}
        setAgeGroup={setAgeGroup}
        // setDenominatorFilter={setDenominatorFilter}
        setInsuranceFilter={setInsuranceFilter}
        setReportFilter={setReportFilter}
        reportFilter={reportFilter}
        setShowCenters={setShowCenters}
      />
      <Map
        // year={year}
        ageGroup={ageGroup}
        // denominatorFilter={denominatorFilter}
        insuranceFilter={insuranceFilter}
        reportFilter={reportFilter}
        showCenters={showCenters}
        rk={-1}
      />
      <Footer />
    </div>
  );

  return returnValue;
}

export default OnlineTool;
