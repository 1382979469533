import L from "leaflet";

const iconEllipse = new L.Icon({
  iconUrl: "/ellipse-19.svg",
  iconRetinaUrl: "/ellipse-19.svg",
  iconAnchor: null,
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(60, 75),
  className: "leaflet-div-icon",
});

export { iconEllipse };
