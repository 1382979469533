const LogoSVG = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 1533 342"
    enable-background="new 0 0 1533 342"
  >
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M377.000000,343.000000 
   C357.645782,343.000000 338.291534,343.000000 318.163788,342.700409 
   C318.199402,341.921936 318.962830,341.330109 319.824615,340.981384 
   C330.370087,336.714172 337.922974,326.159729 337.969604,314.999054 
   C338.070587,290.837311 338.010071,266.674835 337.975983,242.512711 
   C337.974152,241.203827 337.608887,239.895477 337.312866,237.919922 
   C329.481506,246.224167 322.510223,253.920212 315.205811,261.285889 
   C305.801788,270.768890 294.615540,278.451752 289.781616,279.052795 
   C291.217468,277.368164 292.571838,275.938904 293.747589,274.375336 
   C308.515503,254.735687 321.344543,233.925232 331.842255,211.678391 
   C332.681580,209.899628 334.869171,208.757095 336.430237,207.318893 
   C336.813293,207.570801 337.196350,207.822693 337.579407,208.074585 
   C337.579407,185.137527 337.579407,162.200455 337.579407,139.263382 
   C337.183258,139.162674 336.787140,139.061951 336.390991,138.961243 
   C331.897400,152.346100 327.403809,165.730957 322.799438,179.445816 
   C306.751160,172.638229 291.508636,166.172440 276.302307,159.721985 
   C294.227264,114.326782 307.648438,68.207550 313.385315,20.022865 
   C313.854309,19.763948 314.323303,19.505032 314.792328,19.246115 
   C322.165222,27.267780 329.538116,35.289448 336.911041,43.311111 
   C337.998535,39.245296 338.302185,35.762650 337.943085,32.349731 
   C336.737885,20.895420 331.687012,10.641104 319.492706,7.552320 
   C310.845306,5.361951 301.518646,5.735759 292.478790,5.360439 
   C291.347656,5.313476 289.480713,7.490442 289.024628,8.967360 
   C279.577789,39.558624 270.611877,70.302399 260.825653,100.783180 
   C255.945435,115.983368 249.620209,130.719635 243.842056,145.946564 
   C220.307068,135.968201 197.222824,126.180954 173.936737,116.308113 
   C179.042084,104.979706 183.621841,93.542824 189.229370,82.634254 
   C201.779236,58.220406 214.756317,34.024078 227.777557,9.856705 
   C229.935440,5.851676 228.446747,5.158391 224.975266,5.145574 
   C216.976868,5.116044 208.970276,5.347908 200.982956,5.048394 
   C196.636215,4.885398 194.286026,6.625257 191.863922,10.163880 
   C173.317307,37.259846 157.701599,65.963127 144.031143,95.769829 
   C143.032349,97.947594 141.800308,100.018379 140.658417,102.173424 
   C124.877625,95.486328 109.611755,89.017433 94.224571,82.497131 
   C106.263809,55.281624 121.993240,30.521315 140.046768,5.800047 
   C131.384552,5.800047 124.055550,5.729079 116.731766,5.892411 
   C115.844528,5.912198 114.732101,7.030284 114.151131,7.918653 
   C104.520233,22.645262 94.770981,37.300163 85.462646,52.229507 
   C81.141487,59.160084 77.707481,66.643784 73.857323,73.903084 
   C68.353264,71.503426 63.199848,69.256638 57.911457,66.950996 
   C70.612419,42.565723 87.692421,22.844896 109.189636,6.553845 
   C107.301605,5.640321 105.770821,5.169737 104.239174,5.166909 
   C79.410545,5.121069 54.578777,4.905839 29.754440,5.235433 
   C18.117800,5.389934 8.792146,10.356490 3.293812,21.228266 
   C2.900230,22.006487 1.781539,22.417988 1.000000,23.000000 
   C1.000000,15.673555 1.000000,8.347112 1.000000,1.000000 
   C148.687561,1.000000 296.375122,1.000000 444.558685,1.427503 
   C443.712463,2.236453 442.363861,2.969372 441.028961,2.945251 
   C426.887817,2.689736 413.756622,6.357732 401.509796,13.204923 
   C387.970123,20.774935 378.135071,31.641735 371.829529,45.982563 
   C365.442627,60.508427 364.354462,75.657524 365.035370,91.119019 
   C365.686951,105.914635 369.906586,119.733467 378.512115,131.799362 
   C393.579620,152.925766 415.407593,161.316452 440.351105,162.934814 
   C455.603302,163.924362 470.799683,162.453217 485.606171,158.840698 
   C498.928162,155.590363 504.272461,151.309433 506.771973,139.290878 
   C507.404694,136.248535 507.493958,133.093155 507.894012,129.524597 
   C507.969788,116.100014 508.010712,103.140442 507.961243,90.181221 
   C507.955902,88.781059 507.426483,87.382904 507.087158,85.590797 
   C506.850189,77.157745 499.420776,72.194870 493.415100,72.092216 
   C479.467468,71.853806 465.509125,71.813889 451.562866,72.087708 
   C443.517700,72.245659 437.296204,79.360336 438.180359,87.369629 
   C439.087494,95.586899 443.528473,101.138542 452.546295,101.021431 
   C458.023560,100.950287 463.503693,101.096382 468.988525,101.601410 
   C469.080353,110.724052 469.166168,119.390182 468.968048,128.362030 
   C460.093414,129.113312 451.454773,130.348114 442.922485,129.830444 
   C427.877594,128.917664 415.673157,122.285606 410.661835,106.265968 
   C407.116180,94.931557 406.839325,83.470978 407.791901,71.600388 
   C409.603699,49.022793 428.015289,32.994545 450.146667,36.054100 
   C461.071106,37.564346 471.494141,40.420418 481.049164,46.359631 
   C489.128906,51.381886 497.657043,49.466980 501.778870,42.333088 
   C505.340576,36.168564 505.326691,22.508585 498.125275,17.910276 
   C483.666443,8.677927 468.526581,2.373289 451.034515,2.906205 
   C449.710266,2.946550 448.345795,1.666356 447.000000,1.000001 
   C541.687561,1.000000 636.375122,1.000000 731.603516,1.386933 
   C731.479004,2.169908 730.861084,2.743294 730.141296,2.935578 
   C717.848022,6.219561 706.569397,11.477139 697.278320,20.340542 
   C680.696106,36.159523 675.000671,56.525784 674.130981,78.504974 
   C673.647461,90.726791 675.563110,102.814499 679.977417,114.375427 
   C687.038696,132.868210 699.293396,146.374008 717.609558,154.333572 
   C735.381714,162.056763 753.681580,162.717194 772.379517,159.060287 
   C798.466431,153.958221 816.163269,138.774902 825.426758,114.007683 
   C831.075684,98.904457 832.096313,83.255295 830.160889,67.203171 
   C828.712463,55.190350 825.441772,43.869076 819.047668,33.766479 
   C809.148743,18.126234 795.054626,7.850010 776.881409,3.421775 
   C775.474426,3.078925 774.288635,1.828556 773.000000,0.999997 
   C1026.563721,1.000000 1280.127319,1.000000 1534.000000,1.000000 
   C1534.000000,106.020691 1534.000000,211.041687 1533.673828,316.725433 
   C1531.257446,314.653809 1529.138550,311.940979 1527.082520,309.181274 
   C1519.094727,298.459320 1513.571533,285.434235 1498.985474,280.297119 
   C1500.298340,279.605865 1500.830566,279.257263 1501.410522,279.030457 
   C1510.245972,275.575104 1517.656006,270.335999 1523.197388,262.465027 
   C1531.512695,250.653519 1532.404175,237.371567 1530.709595,223.779388 
   C1529.547852,214.461121 1525.747437,205.830536 1518.657471,199.517609 
   C1507.898438,189.937881 1494.658569,186.211990 1480.498779,186.059570 
   C1459.537964,185.833954 1438.568481,186.251602 1417.611816,185.878189 
   C1409.410278,185.732040 1399.264893,192.375748 1399.551758,203.269745 
   C1399.166626,204.215393 1399.022339,204.873886 1399.022095,205.532455 
   C1399.007935,244.459122 1398.997681,283.385803 1399.037964,322.312439 
   C1399.039307,323.547546 1399.573242,324.782104 1399.910889,326.419434 
   C1400.448120,332.838654 1402.951538,337.613281 1408.304688,340.750397 
   C1409.052490,341.188568 1409.443726,342.235077 1410.000000,343.000000 
   C1397.979126,343.000000 1385.958130,343.000000 1373.366699,342.638336 
   C1372.987915,342.012146 1373.120972,341.621918 1373.379883,341.501312 
   C1382.070557,337.452057 1385.374512,331.950073 1383.894287,322.804382 
   C1382.929321,316.842163 1379.987671,310.971741 1369.457275,309.915985 
   C1368.518433,309.821838 1367.611450,309.410156 1366.245972,309.092346 
   C1347.249878,309.019501 1328.697388,309.000244 1310.092041,308.516113 
   C1310.039185,298.479553 1310.039185,288.907837 1310.039185,278.999542 
   C1312.632080,278.999542 1314.443970,278.999634 1316.255737,278.999512 
   C1332.227051,278.998596 1348.198608,279.028748 1364.169800,278.983002 
   C1371.698242,278.961426 1378.023682,273.760040 1379.799194,266.308197 
   C1381.405396,259.566589 1377.788574,251.742752 1371.206055,248.759903 
   C1368.301758,247.443893 1364.968506,247.074432 1361.383789,246.174576 
   C1344.006592,246.042908 1327.075684,246.011932 1310.092041,245.516113 
   C1310.039185,236.471985 1310.039185,227.892685 1310.039185,219.001556 
   C1312.658936,219.001556 1314.473511,219.001633 1316.287964,219.001556 
   C1332.771851,219.000809 1349.258423,219.165909 1365.738403,218.911545 
   C1371.706665,218.819412 1377.703003,217.703751 1381.389282,212.067673 
   C1385.151855,206.314728 1385.234741,200.018890 1382.308594,194.134674 
   C1379.332764,188.150299 1373.542236,186.038101 1367.102661,186.025558 
   C1339.962402,185.972672 1312.822266,185.994919 1285.682007,186.029709 
   C1284.394043,186.031372 1283.065063,186.275574 1281.825439,186.643356 
   C1272.276001,189.476334 1269.401733,193.796844 1268.551880,206.269623 
   C1268.166626,207.215225 1268.022339,207.873672 1268.022095,208.532181 
   C1268.007812,245.459061 1267.997070,282.385956 1268.037964,319.312805 
   C1268.039307,320.547821 1268.573242,321.782257 1268.910645,323.445770 
   C1268.753662,332.181610 1271.854614,338.325745 1280.132080,341.194580 
   C1280.576538,341.348633 1280.717651,342.378082 1281.000000,343.000000 
   C1268.312378,343.000000 1255.624878,343.000000 1242.364258,342.640472 
   C1245.166260,339.108246 1250.283447,336.527191 1251.598389,332.654999 
   C1256.023438,319.623383 1250.253418,308.906403 1234.078979,308.983765 
   C1217.246216,309.064331 1200.412964,309.009949 1183.579834,308.991180 
   C1182.016357,308.989441 1180.453125,308.818909 1178.218628,308.687195 
   C1180.108398,306.107178 1181.543457,304.145477 1182.981079,302.185760 
   C1200.141968,278.791992 1217.388794,255.460541 1234.417114,231.970657 
   C1240.165039,224.041580 1247.493042,217.020737 1249.229736,206.629807 
   C1251.021729,195.908737 1243.495361,186.107361 1233.930542,186.061600 
   C1203.265137,185.914841 1172.593750,185.750931 1141.937500,186.301651 
   C1137.636475,186.378906 1131.961182,189.376663 1129.476440,192.849915 
   C1125.594482,198.276459 1125.431274,205.332687 1128.940796,211.668472 
   C1132.219849,217.588013 1137.658813,219.100555 1144.060059,219.049072 
   C1161.696655,218.907196 1179.335083,219.000046 1197.390991,219.000046 
   C1196.935181,220.328735 1196.827148,221.375534 1196.289185,222.105591 
   C1181.035034,242.804764 1165.726929,263.464264 1150.452026,284.148254 
   C1143.880981,293.046143 1137.175293,301.853180 1130.872803,310.938782 
   C1123.748047,321.209869 1124.689697,335.073029 1136.647461,340.967468 
   C1137.278809,341.278748 1137.557251,342.306061 1138.000000,343.000000 
   C1110.312378,343.000000 1082.624878,343.000000 1054.271973,342.677246 
   C1062.124634,336.507294 1064.609619,328.321533 1064.137207,318.264130 
   C1063.551392,305.792572 1064.653076,293.225739 1063.765991,280.789948 
   C1063.175415,272.509491 1066.249756,266.767578 1071.382202,260.997314 
   C1084.579102,246.160660 1097.515381,231.090515 1110.447998,216.021118 
   C1117.418579,207.898773 1116.265869,197.108826 1108.065552,189.518372 
   C1101.520508,183.460098 1088.772339,182.505188 1082.398682,190.072968 
   C1073.767090,200.321640 1064.998413,210.454681 1056.321289,220.665085 
   C1051.968994,225.786545 1047.688965,230.969543 1043.027588,236.541473 
   C1040.044067,233.021530 1037.403320,229.913559 1034.770752,226.798798 
   C1024.737793,214.928497 1014.735046,203.032349 1004.661560,191.196426 
   C1000.788452,186.645752 996.022644,184.636139 989.781494,184.851196 
   C982.051758,185.117538 977.033447,188.953064 973.490295,195.130554 
   C969.819519,201.530487 969.658386,208.194214 974.557007,214.063553 
   C987.358093,229.401367 1000.219788,244.694778 1013.375244,259.727722 
   C1018.825928,265.956268 1022.939575,271.789917 1022.272034,281.019867 
   C1021.240906,295.277527 1022.043579,309.664948 1021.983765,323.996979 
   C1021.956238,330.592255 1024.612671,335.789856 1029.591431,339.980865 
   C1030.557251,340.793793 1031.205444,341.983856 1032.000000,343.000000 
   C1013.979126,343.000000 995.958191,343.000000 977.311401,342.646790 
   C977.006836,341.925446 977.255310,341.389435 977.660156,341.213715 
   C987.027466,337.147736 990.089294,330.672119 987.926941,319.406372 
   C986.640686,312.704620 979.629150,308.011414 970.841492,308.005371 
   C955.850708,307.995056 940.859924,308.003235 925.869141,308.003662 
   C923.759033,308.003723 921.648987,308.003662 919.001526,308.003662 
   C919.001526,305.418182 919.001587,303.463562 919.001587,301.508942 
   C919.001465,269.695160 919.069763,237.881042 918.904358,206.068130 
   C918.886108,202.555832 918.198242,198.723251 916.668518,195.604416 
   C912.413269,186.928268 904.672241,184.625015 895.519958,184.936249 
   C884.887207,185.297882 877.023743,192.832993 877.015137,203.446945 
   C876.981995,244.088593 877.008423,284.730286 876.995911,325.371948 
   C876.993652,332.781982 880.449768,337.865295 887.107361,340.964966 
   C887.885437,341.327209 888.376099,342.306702 889.000000,343.000000 
   C876.312439,343.000000 863.624878,343.000000 850.319031,342.658020 
   C851.020508,341.364288 852.332458,340.401428 853.661194,339.462463 
   C862.033875,333.545715 863.240662,325.777863 859.640503,316.706299 
   C855.447083,306.140076 849.906250,296.106232 844.905396,285.863251 
   C830.621399,256.605927 816.285278,227.373947 802.034790,198.100311 
   C797.867615,189.540039 791.116150,185.422729 781.664612,184.943253 
   C771.092590,184.406952 762.882080,187.759781 758.086060,197.693375 
   C752.265869,209.748291 746.453186,221.806931 740.591675,233.841827 
   C727.276428,261.181000 713.933533,288.506653 700.601501,315.837646 
   C695.861023,325.555878 698.075500,333.485107 707.141785,339.742859 
   C708.294678,340.538574 709.056824,341.900421 710.000000,343.000000 
   C697.979126,343.000000 685.958191,343.000000 673.308533,342.664734 
   C678.509705,337.867584 682.991211,332.601685 682.992737,324.737244 
   C683.000671,284.574280 683.073792,244.410904 682.862610,204.249069 
   C682.843750,200.651138 681.597656,196.698593 679.806641,193.543686 
   C675.647888,186.218048 665.778076,182.914719 657.222839,185.352219 
   C650.327820,187.316696 643.310852,194.014618 643.551514,204.270233 
   C643.162415,206.715836 643.018066,208.873993 643.009827,211.032684 
   C642.933472,231.016739 642.880615,251.000870 642.528320,270.833405 
   C633.823425,260.292969 625.382141,249.927414 617.005859,239.509659 
   C605.223938,224.856094 593.613770,210.061737 581.648621,195.559845 
   C578.656616,191.933548 575.088318,187.736008 570.925293,186.309326 
   C555.419373,180.995316 543.166626,189.960159 543.075195,205.592072 
   C542.845398,244.895981 542.942200,284.202301 543.052551,323.507233 
   C543.071838,330.385590 545.678040,336.307953 551.419617,340.577667 
   C552.145447,341.117432 552.482666,342.179779 553.000000,343.000000 
   C540.979126,343.000000 528.958191,343.000000 516.308350,342.658386 
   C516.295166,341.809387 516.839722,341.155823 517.537170,340.816528 
   C528.484741,335.489960 530.456726,324.280945 525.505432,314.921783 
   C521.961365,308.222534 519.010376,301.212189 515.681458,294.395966 
   C500.386292,263.077881 484.938080,231.833862 469.793304,200.443344 
   C464.644958,189.772369 457.106110,184.199768 444.829224,184.870071 
   C435.637848,185.371857 428.968536,189.134964 424.980347,197.231369 
   C411.554016,224.488174 398.289948,251.824890 384.963074,279.130676 
   C379.023651,291.300110 373.114075,303.484222 367.131439,315.632355 
   C362.900787,324.222961 364.315308,335.393341 374.425415,340.177826 
   C375.490295,340.681793 376.151489,342.038788 377.000000,343.000000 
M638.802124,4.038780 
   C613.304932,4.074535 587.807800,4.110291 561.485474,4.099378 
   C560.722412,4.389108 559.977417,4.864385 559.193542,4.940597 
   C548.241150,6.005494 543.214355,12.840591 543.141968,22.111446 
   C542.824646,62.748718 542.932129,103.390083 543.058838,144.029419 
   C543.085754,152.639496 550.835083,160.902542 558.774475,160.940063 
   C586.755310,161.072266 614.737732,161.082458 642.718262,160.924698 
   C650.308411,160.881912 656.641357,155.926712 658.011536,149.860611 
   C661.008362,136.591965 654.115356,128.020493 640.400574,128.002808 
   C626.243286,127.984550 612.085999,127.998634 597.928711,127.998589 
   C593.477966,127.998573 589.027222,127.998596 584.418213,127.998596 
   C584.418213,117.717056 584.418213,108.028717 584.418213,98.001549 
   C586.649597,98.001549 588.463623,98.001625 590.277649,98.001526 
   C605.933960,98.000702 621.590454,98.049721 637.246460,97.979378 
   C645.956909,97.940247 653.184082,92.775696 653.846069,85.502480 
   C654.617737,77.023964 654.536377,69.337280 642.581177,66.052330 
   C640.553528,65.495193 638.416687,65.057182 636.327698,65.045807 
   C620.838379,64.961525 605.348511,64.999077 589.858765,64.998375 
   C588.083069,64.998291 586.307373,64.998360 584.145447,64.998360 
   C584.145447,55.582062 584.145447,46.799706 585.058411,38.005451 
   C603.647888,37.912411 622.237366,37.819366 641.593628,37.521645 
   C642.230530,37.357796 642.860474,37.088928 643.505432,37.045959 
   C652.316895,36.458900 658.408508,31.153694 658.887146,22.804539 
   C659.391846,14.001287 656.087341,7.698852 646.428955,5.311864 
   C644.221313,4.766272 641.938049,4.526516 638.802124,4.038780 
z"
    />
    <path
      fill="#009CA6"
      opacity="1.000000"
      stroke="none"
      d="
M1.000000,23.468658 
   C1.781539,22.417988 2.900230,22.006487 3.293812,21.228266 
   C8.792146,10.356490 18.117800,5.389934 29.754440,5.235433 
   C54.578777,4.905839 79.410545,5.121069 104.239174,5.166909 
   C105.770821,5.169737 107.301605,5.640321 109.189636,6.553845 
   C87.692421,22.844896 70.612419,42.565723 57.911457,66.950996 
   C63.199848,69.256638 68.353264,71.503426 73.857323,73.903084 
   C77.707481,66.643784 81.141487,59.160084 85.462646,52.229507 
   C94.770981,37.300163 104.520233,22.645262 114.151131,7.918653 
   C114.732101,7.030284 115.844528,5.912198 116.731766,5.892411 
   C124.055550,5.729079 131.384552,5.800047 140.046768,5.800047 
   C121.993240,30.521315 106.263809,55.281624 94.224571,82.497131 
   C109.611755,89.017433 124.877625,95.486328 140.658417,102.173424 
   C141.800308,100.018379 143.032349,97.947594 144.031143,95.769829 
   C157.701599,65.963127 173.317307,37.259846 191.863922,10.163880 
   C194.286026,6.625257 196.636215,4.885398 200.982956,5.048394 
   C208.970276,5.347908 216.976868,5.116044 224.975266,5.145574 
   C228.446747,5.158391 229.935440,5.851676 227.777557,9.856705 
   C214.756317,34.024078 201.779236,58.220406 189.229370,82.634254 
   C183.621841,93.542824 179.042084,104.979706 173.936737,116.308113 
   C197.222824,126.180954 220.307068,135.968201 243.842056,145.946564 
   C249.620209,130.719635 255.945435,115.983368 260.825653,100.783180 
   C270.611877,70.302399 279.577789,39.558624 289.024628,8.967360 
   C289.480713,7.490442 291.347656,5.313476 292.478790,5.360439 
   C301.518646,5.735759 310.845306,5.361951 319.492706,7.552320 
   C331.687012,10.641104 336.737885,20.895420 337.943085,32.349731 
   C338.302185,35.762650 337.998535,39.245296 336.911041,43.311111 
   C329.538116,35.289448 322.165222,27.267780 314.792328,19.246115 
   C314.323303,19.505032 313.854309,19.763948 313.385315,20.022865 
   C307.648438,68.207550 294.227264,114.326782 276.302307,159.721985 
   C291.508636,166.172440 306.751160,172.638229 322.799438,179.445816 
   C327.403809,165.730957 331.897400,152.346100 336.390991,138.961243 
   C336.787140,139.061951 337.183258,139.162674 337.579407,139.263382 
   C337.579407,162.200455 337.579407,185.137527 337.579407,208.074585 
   C337.196350,207.822693 336.813293,207.570801 336.430237,207.318893 
   C334.869171,208.757095 332.681580,209.899628 331.842255,211.678391 
   C321.344543,233.925232 308.515503,254.735687 293.747589,274.375336 
   C292.571838,275.938904 291.217468,277.368164 289.781616,279.052795 
   C294.615540,278.451752 305.801788,270.768890 315.205811,261.285889 
   C322.510223,253.920212 329.481506,246.224167 337.312866,237.919922 
   C337.608887,239.895477 337.974152,241.203827 337.975983,242.512711 
   C338.010071,266.674835 338.070587,290.837311 337.969604,314.999054 
   C337.922974,326.159729 330.370087,336.714172 319.824615,340.981384 
   C318.962830,341.330109 318.199402,341.921936 317.695129,342.700409 
   C219.312439,343.000000 120.624878,343.000000 21.786083,342.704895 
   C21.304482,342.038696 21.039118,341.449219 20.634136,341.328674 
   C12.412322,338.880646 6.543254,333.775146 2.932188,325.990662 
   C2.692080,325.473053 1.662425,325.321716 0.999999,325.000000 
   C1.000000,224.645767 1.000000,124.291542 1.000000,23.468658 
M201.338455,151.082062 
   C189.449783,146.118805 177.561127,141.155563 165.260040,136.020126 
   C161.791992,145.187943 158.431290,154.383652 154.847366,163.491531 
   C140.339005,200.361694 128.184311,237.980698 118.913254,276.507904 
   C118.541580,278.052429 119.359528,280.974915 120.532143,281.631104 
   C132.686157,288.432678 145.189926,294.421173 158.723709,298.197357 
   C163.178391,299.440308 165.154358,298.436005 167.298050,294.686432 
   C176.105545,279.280975 185.747803,264.329071 194.043961,248.660233 
   C208.469818,221.414276 222.126816,193.761230 236.198227,166.097885 
   C223.900650,160.848770 212.949692,156.174454 201.338455,151.082062 
M124.903458,142.298752 
   C126.832680,137.086319 128.352737,131.669495 130.822006,126.726845 
   C132.759995,122.847649 131.418076,121.424133 128.112335,120.047462 
   C115.454750,114.776260 102.796539,109.501747 90.234200,104.009720 
   C86.709267,102.468681 85.091019,102.783188 83.858612,106.799454 
   C79.642281,120.539993 74.383881,133.991623 70.773254,147.878540 
   C67.079628,162.084702 64.668159,176.639465 62.068806,191.108704 
   C60.676342,198.859802 59.999168,206.744690 59.176678,214.587814 
   C59.036983,215.919907 59.430519,217.603546 60.195583,218.689453 
   C68.433899,230.382599 76.667793,242.084015 85.209137,253.555161 
   C87.525612,256.666229 90.873199,259.009521 93.690880,261.652100 
   C97.498482,242.940567 100.280876,225.096786 104.877258,207.733200 
   C110.644608,185.946091 117.898956,164.552582 124.903458,142.298752 
M284.525238,252.978668 
   C295.648834,235.790649 305.758820,218.061493 314.052856,199.047256 
   C298.282135,192.387756 283.132935,185.990707 267.921417,179.567352 
   C247.924713,224.023071 224.026962,265.474640 193.348831,303.827240 
   C196.493057,304.294952 198.904236,305.131622 201.218903,304.918732 
   C214.170319,303.727600 227.144928,302.603882 240.007568,300.739288 
   C244.142715,300.139862 249.231125,298.403107 251.707047,295.395203 
   C262.908936,281.786530 273.331543,267.536438 284.525238,252.978668 
M40.910179,130.307312 
   C38.650795,145.455658 41.480461,160.167862 44.287090,174.880585 
   C48.314636,147.141876 55.480900,120.363632 65.791092,93.923981 
   C60.373528,91.570206 55.030991,89.249023 49.519730,86.854538 
   C44.851490,100.880516 41.289455,114.793983 40.910179,130.307312 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M643.859375,203.983032 
   C643.310852,194.014618 650.327820,187.316696 657.222839,185.352219 
   C665.778076,182.914719 675.647888,186.218048 679.806641,193.543686 
   C681.597656,196.698593 682.843750,200.651138 682.862610,204.249069 
   C683.073792,244.410904 683.000671,284.574280 682.992737,324.737244 
   C682.991211,332.601685 678.509705,337.867584 672.839844,342.664734 
   C666.645569,343.000000 660.291199,343.000000 653.660034,342.674927 
   C651.441650,340.587463 649.231018,339.042480 647.602539,337.027130 
   C637.665833,324.729462 627.914795,312.281982 617.998840,299.967407 
   C606.434814,285.606171 594.765198,271.329987 582.889160,257.157715 
   C582.427368,258.247772 582.034973,259.197144 582.035400,260.146362 
   C582.044373,281.769958 582.096252,303.393585 582.088501,325.432800 
   C581.374451,328.494324 581.220642,331.426819 579.929321,333.718201 
   C578.049194,337.054260 575.349915,339.928711 573.000000,343.000000 
   C566.645569,343.000000 560.291199,343.000000 553.468384,343.000000 
   C552.482666,342.179779 552.145447,341.117432 551.419617,340.577667 
   C545.678040,336.307953 543.071838,330.385590 543.052551,323.507233 
   C542.942200,284.202301 542.845398,244.895981 543.075195,205.592072 
   C543.166626,189.960159 555.419373,180.995316 570.925293,186.309326 
   C575.088318,187.736008 578.656616,191.933548 581.648621,195.559845 
   C593.613770,210.061737 605.223938,224.856094 617.005859,239.509659 
   C625.382141,249.927414 633.823425,260.292969 642.785828,270.695068 
   C643.554321,269.762787 643.963318,268.817139 643.962952,267.871613 
   C643.955505,246.575424 643.903198,225.279221 643.859375,203.983032 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M1410.468506,343.000000 
   C1409.443726,342.235077 1409.052490,341.188568 1408.304688,340.750397 
   C1402.951538,337.613281 1400.448120,332.838654 1399.946045,325.952942 
   C1399.905884,284.383484 1399.882935,243.683060 1399.859863,202.982635 
   C1399.264893,192.375748 1409.410278,185.732040 1417.611816,185.878189 
   C1438.568481,186.251602 1459.537964,185.833954 1480.498779,186.059570 
   C1494.658569,186.211990 1507.898438,189.937881 1518.657471,199.517609 
   C1525.747437,205.830536 1529.547852,214.461121 1530.709595,223.779388 
   C1532.404175,237.371567 1531.512695,250.653519 1523.197388,262.465027 
   C1517.656006,270.335999 1510.245972,275.575104 1501.410522,279.030457 
   C1500.830566,279.257263 1500.298340,279.605865 1498.985474,280.297119 
   C1513.571533,285.434235 1519.094727,298.459320 1527.082520,309.181274 
   C1529.138550,311.940979 1531.257446,314.653809 1533.673828,317.194092 
   C1534.000000,323.021149 1534.000000,329.042297 1533.664551,335.364868 
   C1531.219360,338.110901 1529.109619,340.555450 1527.000000,343.000000 
   C1519.645752,343.000000 1512.291504,343.000000 1504.655396,342.674347 
   C1501.567627,339.373657 1498.353271,336.685394 1496.027954,333.372955 
   C1486.867065,320.323608 1478.167847,306.947418 1468.879639,293.991852 
   C1466.375610,290.499115 1462.805786,286.502686 1459.001831,285.510498 
   C1453.471069,284.067932 1447.284058,285.140442 1440.996948,285.140442 
   C1440.996948,297.723846 1440.917480,309.666687 1441.024414,321.607880 
   C1441.098145,329.858246 1438.859863,336.843872 1431.400024,341.391052 
   C1431.064453,341.595581 1431.124268,342.448853 1431.000000,343.000000 
   C1424.312378,343.000000 1417.624634,343.000000 1410.468506,343.000000 
M1469.924194,252.031647 
   C1473.509644,251.668198 1477.174194,251.655182 1480.663940,250.867661 
   C1487.713257,249.276917 1492.389160,242.977905 1491.936646,235.513855 
   C1491.419312,226.982483 1488.514160,221.986389 1480.496338,219.935745 
   C1467.426636,216.593048 1454.187256,218.447968 1440.574219,218.814240 
   C1440.417847,229.104858 1440.168335,239.396164 1440.243774,249.685104 
   C1440.251587,250.759460 1442.181885,252.740143 1443.222168,252.741348 
   C1451.826538,252.751404 1460.431152,252.393784 1469.924194,252.031647 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M772.531372,0.999997 
   C774.288635,1.828556 775.474426,3.078925 776.881409,3.421775 
   C795.054626,7.850010 809.148743,18.126234 819.047668,33.766479 
   C825.441772,43.869076 828.712463,55.190350 830.160889,67.203171 
   C832.096313,83.255295 831.075684,98.904457 825.426758,114.007683 
   C816.163269,138.774902 798.466431,153.958221 772.379517,159.060287 
   C753.681580,162.717194 735.381714,162.056763 717.609558,154.333572 
   C699.293396,146.374008 687.038696,132.868210 679.977417,114.375427 
   C675.563110,102.814499 673.647461,90.726791 674.130981,78.504974 
   C675.000671,56.525784 680.696106,36.159523 697.278320,20.340542 
   C706.569397,11.477139 717.848022,6.219561 730.141296,2.935578 
   C730.861084,2.743294 731.479004,2.169908 732.072144,1.386933 
   C745.354248,1.000000 758.708435,1.000000 772.531372,0.999997 
M746.606689,33.998966 
   C733.940308,35.125881 722.372314,45.901917 719.022644,59.517685 
   C715.871338,72.327171 714.942505,85.145515 718.002197,98.126823 
   C721.163452,111.538948 727.282349,122.624657 741.547241,126.565926 
   C761.440002,132.062073 779.652466,124.023880 786.054565,103.207428 
   C789.983154,90.433586 789.973022,77.459854 787.632507,64.497849 
   C785.870239,54.738785 781.958374,45.964603 773.498901,39.902870 
   C765.639771,34.271290 756.915283,32.606544 746.606689,33.998966 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M446.666687,1.000001 
   C448.345795,1.666356 449.710266,2.946550 451.034515,2.906205 
   C468.526581,2.373289 483.666443,8.677927 498.125275,17.910276 
   C505.326691,22.508585 505.340576,36.168564 501.778870,42.333088 
   C497.657043,49.466980 489.128906,51.381886 481.049164,46.359631 
   C471.494141,40.420418 461.071106,37.564346 450.146667,36.054100 
   C428.015289,32.994545 409.603699,49.022793 407.791901,71.600388 
   C406.839325,83.470978 407.116180,94.931557 410.661835,106.265968 
   C415.673157,122.285606 427.877594,128.917664 442.922485,129.830444 
   C451.454773,130.348114 460.093414,129.113312 469.133911,127.978806 
   C469.383270,118.574860 469.182892,109.859879 468.982483,101.144897 
   C463.503693,101.096382 458.023560,100.950287 452.546295,101.021431 
   C443.528473,101.138542 439.087494,95.586899 438.180359,87.369629 
   C437.296204,79.360336 443.517700,72.245659 451.562866,72.087708 
   C465.509125,71.813889 479.467468,71.853806 493.415100,72.092216 
   C499.420776,72.194870 506.850189,77.157745 507.052307,86.057251 
   C507.046967,98.008125 506.960052,109.099983 507.039185,120.190651 
   C507.062500,123.458725 507.555298,126.723450 507.832947,129.989716 
   C507.493958,133.093155 507.404694,136.248535 506.771973,139.290878 
   C504.272461,151.309433 498.928162,155.590363 485.606171,158.840698 
   C470.799683,162.453217 455.603302,163.924362 440.351105,162.934814 
   C415.407593,161.316452 393.579620,152.925766 378.512115,131.799362 
   C369.906586,119.733467 365.686951,105.914635 365.035370,91.119019 
   C364.354462,75.657524 365.442627,60.508427 371.829529,45.982563 
   C378.135071,31.641735 387.970123,20.774935 401.509796,13.204923 
   C413.756622,6.357732 426.887817,2.689736 441.028961,2.945251 
   C442.363861,2.969372 443.712463,2.236453 445.027344,1.427503 
   C445.444458,1.000000 445.888885,1.000000 446.666687,1.000001 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M1281.437500,343.000000 
   C1280.717651,342.378082 1280.576538,341.348633 1280.132080,341.194580 
   C1271.854614,338.325745 1268.753662,332.181610 1268.945801,322.979309 
   C1268.906006,283.383514 1268.883057,244.683014 1268.859985,205.982498 
   C1269.401733,193.796844 1272.276001,189.476334 1281.825439,186.643356 
   C1283.065063,186.275574 1284.394043,186.031372 1285.682007,186.029709 
   C1312.822266,185.994919 1339.962402,185.972672 1367.102661,186.025558 
   C1373.542236,186.038101 1379.332764,188.150299 1382.308594,194.134674 
   C1385.234741,200.018890 1385.151855,206.314728 1381.389282,212.067673 
   C1377.703003,217.703751 1371.706665,218.819412 1365.738403,218.911545 
   C1349.258423,219.165909 1332.771851,219.000809 1316.287964,219.001556 
   C1314.473511,219.001633 1312.658936,219.001556 1310.039185,219.001556 
   C1310.039185,227.892685 1310.039185,236.471985 1310.425537,245.737823 
   C1325.202881,246.616531 1339.593506,246.849625 1353.985107,246.963501 
   C1356.598145,246.984177 1359.214966,246.517120 1361.830078,246.275238 
   C1364.968506,247.074432 1368.301758,247.443893 1371.206055,248.759903 
   C1377.788574,251.742752 1381.405396,259.566589 1379.799194,266.308197 
   C1378.023682,273.760040 1371.698242,278.961426 1364.169800,278.983002 
   C1348.198608,279.028748 1332.227051,278.998596 1316.255737,278.999512 
   C1314.443970,278.999634 1312.632080,278.999542 1310.039185,278.999542 
   C1310.039185,288.907837 1310.039185,298.479553 1310.425537,308.737793 
   C1311.781006,309.605164 1312.749634,309.941345 1313.718994,309.943634 
   C1330.103760,309.982391 1346.488525,309.998291 1362.873169,309.949738 
   C1364.146118,309.945984 1365.417603,309.425995 1366.689697,309.145966 
   C1367.611450,309.410156 1368.518433,309.821838 1369.457275,309.915985 
   C1379.987671,310.971741 1382.929321,316.842163 1383.894287,322.804382 
   C1385.374512,331.950073 1382.070557,337.452057 1373.379883,341.501312 
   C1373.120972,341.621918 1372.987915,342.012146 1372.898071,342.638336 
   C1370.629395,343.000000 1368.258911,343.000000 1365.091553,342.781250 
   C1362.991333,342.378632 1361.688110,342.036407 1360.384277,342.034454 
   C1337.849609,342.000916 1315.314819,341.989197 1292.780273,342.051117 
   C1291.186035,342.055511 1289.593384,342.669464 1288.000000,343.000000 
   C1285.958374,343.000000 1283.916626,343.000000 1281.437500,343.000000 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M377.466858,343.000000 
   C376.151489,342.038788 375.490295,340.681793 374.425415,340.177826 
   C364.315308,335.393341 362.900787,324.222961 367.131439,315.632355 
   C373.114075,303.484222 379.023651,291.300110 384.963074,279.130676 
   C398.289948,251.824890 411.554016,224.488174 424.980347,197.231369 
   C428.968536,189.134964 435.637848,185.371857 444.829224,184.870071 
   C457.106110,184.199768 464.644958,189.772369 469.793304,200.443344 
   C484.938080,231.833862 500.386292,263.077881 515.681458,294.395966 
   C519.010376,301.212189 521.961365,308.222534 525.505432,314.921783 
   C530.456726,324.280945 528.484741,335.489960 517.537170,340.816528 
   C516.839722,341.155823 516.295166,341.809387 515.839722,342.658386 
   C510.977905,343.000000 505.955811,343.000000 500.663696,342.677551 
   C494.365234,338.092407 491.063995,331.866150 488.206909,325.340302 
   C486.425934,321.272430 484.958588,317.049225 482.908081,313.127075 
   C482.180145,311.734741 480.152649,310.149933 478.704987,310.136810 
   C457.549347,309.945007 436.391205,309.968750 415.234497,310.087280 
   C413.857697,310.095001 411.799500,310.928986 411.237396,311.998444 
   C409.177307,315.917633 407.852448,320.221680 405.812622,324.153503 
   C402.529022,330.482910 400.697571,337.936707 393.359955,341.391907 
   C393.055176,341.535461 393.111755,342.446533 393.000000,343.000000 
   C387.977905,343.000000 382.955811,343.000000 377.466858,343.000000 
M433.708527,277.998199 
   C444.647247,277.998199 455.585999,277.998199 467.219757,277.998199 
   C460.309540,262.639587 453.710114,247.971802 446.827393,232.674347 
   C439.903992,248.163651 433.408508,262.695648 426.568665,277.998108 
   C429.226532,277.998108 431.001953,277.998108 433.708527,277.998199 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M710.466858,343.000000 
   C709.056824,341.900421 708.294678,340.538574 707.141785,339.742859 
   C698.075500,333.485107 695.861023,325.555878 700.601501,315.837646 
   C713.933533,288.506653 727.276428,261.181000 740.591675,233.841827 
   C746.453186,221.806931 752.265869,209.748291 758.086060,197.693375 
   C762.882080,187.759781 771.092590,184.406952 781.664612,184.943253 
   C791.116150,185.422729 797.867615,189.540039 802.034790,198.100311 
   C816.285278,227.373947 830.621399,256.605927 844.905396,285.863251 
   C849.906250,296.106232 855.447083,306.140076 859.640503,316.706299 
   C863.240662,325.777863 862.033875,333.545715 853.661194,339.462463 
   C852.332458,340.401428 851.020508,341.364288 849.850342,342.658020 
   C844.977905,343.000000 839.955811,343.000000 834.641907,342.664612 
   C831.284058,339.024414 827.555969,336.111206 825.312805,332.319550 
   C822.238403,327.122650 820.236572,321.281891 817.879700,315.673126 
   C816.309753,311.936951 814.461365,309.744690 809.572571,309.838593 
   C789.463379,310.224670 769.342224,310.047363 749.226074,309.948700 
   C746.354187,309.934631 745.217102,310.956940 743.810303,313.569092 
   C738.474548,323.476135 737.226929,336.050171 725.814636,342.138031 
   C725.743408,342.175995 725.932495,342.701935 726.000000,343.000000 
   C720.977905,343.000000 715.955811,343.000000 710.466858,343.000000 
M791.007080,256.485870 
   C787.603271,248.835892 784.199524,241.185898 780.384338,232.611374 
   C773.450684,248.077820 766.886353,262.720490 760.253113,277.516754 
   C773.874084,277.516754 786.830627,277.516754 800.607056,277.516754 
   C797.234131,270.287628 794.190063,263.763153 791.007080,256.485870 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M1138.437500,343.000000 
   C1137.557251,342.306061 1137.278809,341.278748 1136.647461,340.967468 
   C1124.689697,335.073029 1123.748047,321.209869 1130.872803,310.938782 
   C1137.175293,301.853180 1143.880981,293.046143 1150.452026,284.148254 
   C1165.726929,263.464264 1181.035034,242.804764 1196.289185,222.105591 
   C1196.827148,221.375534 1196.935181,220.328735 1197.390991,219.000046 
   C1179.335083,219.000046 1161.696655,218.907196 1144.060059,219.049072 
   C1137.658813,219.100555 1132.219849,217.588013 1128.940796,211.668472 
   C1125.431274,205.332687 1125.594482,198.276459 1129.476440,192.849915 
   C1131.961182,189.376663 1137.636475,186.378906 1141.937500,186.301651 
   C1172.593750,185.750931 1203.265137,185.914841 1233.930542,186.061600 
   C1243.495361,186.107361 1251.021729,195.908737 1249.229736,206.629807 
   C1247.493042,217.020737 1240.165039,224.041580 1234.417114,231.970657 
   C1217.388794,255.460541 1200.141968,278.791992 1182.981079,302.185760 
   C1181.543457,304.145477 1180.108398,306.107178 1178.218628,308.687195 
   C1180.453125,308.818909 1182.016357,308.989441 1183.579834,308.991180 
   C1200.412964,309.009949 1217.246216,309.064331 1234.078979,308.983765 
   C1250.253418,308.906403 1256.023438,319.623383 1251.598389,332.654999 
   C1250.283447,336.527191 1245.166260,339.108246 1241.895752,342.640472 
   C1239.629395,343.000000 1237.258911,343.000000 1234.092529,342.780487 
   C1231.992188,342.377777 1230.687866,342.036285 1229.382935,342.034729 
   C1202.849976,342.002777 1176.316895,341.992706 1149.784058,342.051453 
   C1148.188721,342.054993 1146.594604,342.669678 1145.000000,343.000000 
   C1142.958374,343.000000 1140.916626,343.000000 1138.437500,343.000000 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M1032.468506,343.000000 
   C1031.205444,341.983856 1030.557251,340.793793 1029.591431,339.980865 
   C1024.612671,335.789856 1021.956238,330.592255 1021.983765,323.996979 
   C1022.043579,309.664948 1021.240906,295.277527 1022.272034,281.019867 
   C1022.939575,271.789917 1018.825928,265.956268 1013.375244,259.727722 
   C1000.219788,244.694778 987.358093,229.401367 974.557007,214.063553 
   C969.658386,208.194214 969.819519,201.530487 973.490295,195.130554 
   C977.033447,188.953064 982.051758,185.117538 989.781494,184.851196 
   C996.022644,184.636139 1000.788452,186.645752 1004.661560,191.196426 
   C1014.735046,203.032349 1024.737793,214.928497 1034.770752,226.798798 
   C1037.403320,229.913559 1040.044067,233.021530 1043.027588,236.541473 
   C1047.688965,230.969543 1051.968994,225.786545 1056.321289,220.665085 
   C1064.998413,210.454681 1073.767090,200.321640 1082.398682,190.072968 
   C1088.772339,182.505188 1101.520508,183.460098 1108.065552,189.518372 
   C1116.265869,197.108826 1117.418579,207.898773 1110.447998,216.021118 
   C1097.515381,231.090515 1084.579102,246.160660 1071.382202,260.997314 
   C1066.249756,266.767578 1063.175415,272.509491 1063.765991,280.789948 
   C1064.653076,293.225739 1063.551392,305.792572 1064.137207,318.264130 
   C1064.609619,328.321533 1062.124634,336.507294 1053.803223,342.677246 
   C1046.979004,343.000000 1039.958130,343.000000 1032.468506,343.000000 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M889.437500,343.000000 
   C888.376099,342.306702 887.885437,341.327209 887.107361,340.964966 
   C880.449768,337.865295 876.993652,332.781982 876.995911,325.371948 
   C877.008423,284.730286 876.981995,244.088593 877.015137,203.446945 
   C877.023743,192.832993 884.887207,185.297882 895.519958,184.936249 
   C904.672241,184.625015 912.413269,186.928268 916.668518,195.604416 
   C918.198242,198.723251 918.886108,202.555832 918.904358,206.068130 
   C919.069763,237.881042 919.001465,269.695160 919.001587,301.508942 
   C919.001587,303.463562 919.001526,305.418182 919.001526,308.003662 
   C921.648987,308.003662 923.759033,308.003723 925.869141,308.003662 
   C940.859924,308.003235 955.850708,307.995056 970.841492,308.005371 
   C979.629150,308.011414 986.640686,312.704620 987.926941,319.406372 
   C990.089294,330.672119 987.027466,337.147736 977.660156,341.213715 
   C977.255310,341.389435 977.006836,341.925446 976.842773,342.646790 
   C974.629456,343.000000 972.258850,343.000000 969.091553,342.781311 
   C967.157227,342.381317 966.019897,342.043640 964.882080,342.041931 
   C943.348694,342.009674 921.815186,341.998383 900.281921,342.058533 
   C898.853760,342.062500 897.427246,342.672119 896.000000,343.000000 
   C893.958313,343.000000 891.916687,343.000000 889.437500,343.000000 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M583.141418,257.016907 
   C594.765198,271.329987 606.434814,285.606171 617.998840,299.967407 
   C627.914795,312.281982 637.665833,324.729462 647.602539,337.027130 
   C649.231018,339.042480 651.441650,340.587463 653.191650,342.674927 
   C626.645752,343.000000 600.291565,343.000000 573.468628,343.000000 
   C575.349915,339.928711 578.049194,337.054260 579.929321,333.718201 
   C581.220642,331.426819 581.374451,328.494324 582.396301,325.146423 
   C582.837219,322.452728 582.983765,320.461182 582.989929,318.469208 
   C583.053467,297.985168 583.094299,277.501007 583.141418,257.016907 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M726.468689,343.000000 
   C725.932495,342.701935 725.743408,342.175995 725.814636,342.138031 
   C737.226929,336.050171 738.474548,323.476135 743.810303,313.569092 
   C745.217102,310.956940 746.354187,309.934631 749.226074,309.948700 
   C769.342224,310.047363 789.463379,310.224670 809.572571,309.838593 
   C814.461365,309.744690 816.309753,311.936951 817.879700,315.673126 
   C820.236572,321.281891 822.238403,327.122650 825.312805,332.319550 
   C827.555969,336.111206 831.284058,339.024414 834.175049,342.664612 
   C798.312439,343.000000 762.624878,343.000000 726.468689,343.000000 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M393.468628,343.000000 
   C393.111755,342.446533 393.055176,341.535461 393.359955,341.391907 
   C400.697571,337.936707 402.529022,330.482910 405.812622,324.153503 
   C407.852448,320.221680 409.177307,315.917633 411.237396,311.998444 
   C411.799500,310.928986 413.857697,310.095001 415.234497,310.087280 
   C436.391205,309.968750 457.549347,309.945007 478.704987,310.136810 
   C480.152649,310.149933 482.180145,311.734741 482.908081,313.127075 
   C484.958588,317.049225 486.425934,321.272430 488.206909,325.340302 
   C491.063995,331.866150 494.365234,338.092407 500.196838,342.677551 
   C464.645782,343.000000 429.291534,343.000000 393.468628,343.000000 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M1431.468506,343.000000 
   C1431.124268,342.448853 1431.064453,341.595581 1431.400024,341.391052 
   C1438.859863,336.843872 1441.098145,329.858246 1441.024414,321.607880 
   C1440.917480,309.666687 1440.996948,297.723846 1440.996948,285.140442 
   C1447.284058,285.140442 1453.471069,284.067932 1459.001831,285.510498 
   C1462.805786,286.502686 1466.375610,290.499115 1468.879639,293.991852 
   C1478.167847,306.947418 1486.867065,320.323608 1496.027954,333.372955 
   C1498.353271,336.685394 1501.567627,339.373657 1504.186768,342.674347 
   C1479.979126,343.000000 1455.958130,343.000000 1431.468506,343.000000 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M0.999999,325.469727 
   C1.662425,325.321716 2.692080,325.473053 2.932188,325.990662 
   C6.543254,333.775146 12.412322,338.880646 20.634136,341.328674 
   C21.039118,341.449219 21.304482,342.038696 21.317427,342.704895 
   C14.411453,343.000000 7.822906,343.000000 1.000000,343.000000 
   C1.000000,337.315277 1.000000,331.627350 0.999999,325.469727 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M1145.468628,343.000000 
   C1146.594604,342.669678 1148.188721,342.054993 1149.784058,342.051453 
   C1176.316895,341.992706 1202.849976,342.002777 1229.382935,342.034729 
   C1230.687866,342.036285 1231.992188,342.377777 1233.648438,342.780487 
   C1204.645752,343.000000 1175.291504,343.000000 1145.468628,343.000000 
z"
    />
    <path
      fill="#007F7F"
      opacity="0.000000"
      stroke="none"
      d="
M1288.468628,343.000000 
   C1289.593384,342.669464 1291.186035,342.055511 1292.780273,342.051117 
   C1315.314819,341.989197 1337.849609,342.000916 1360.384277,342.034454 
   C1361.688110,342.036407 1362.991333,342.378632 1364.647461,342.781250 
   C1339.645752,343.000000 1314.291504,343.000000 1288.468628,343.000000 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M896.468628,343.000000 
   C897.427246,342.672119 898.853760,342.062500 900.281921,342.058533 
   C921.815186,341.998383 943.348694,342.009674 964.882080,342.041931 
   C966.019897,342.043640 967.157227,342.381317 968.647339,342.781311 
   C944.979126,343.000000 920.958191,343.000000 896.468628,343.000000 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M1527.497925,343.000000 
   C1529.109619,340.555450 1531.219360,338.110901 1533.664551,335.833160 
   C1534.000000,338.247620 1534.000000,340.495270 1534.000000,343.000000 
   C1532.001831,343.000000 1529.998901,343.000000 1527.497925,343.000000 
z"
    />
    <path
      fill="#0049A6"
      opacity="1.000000"
      stroke="none"
      d="
M584.145447,38.017349 
   C584.145447,46.799706 584.145447,55.582062 584.145447,64.998360 
   C586.307373,64.998360 588.083069,64.998291 589.858765,64.998375 
   C605.348511,64.999077 620.838379,64.961525 636.327698,65.045807 
   C638.416687,65.057182 640.553528,65.495193 642.581177,66.052330 
   C654.536377,69.337280 654.617737,77.023964 653.846069,85.502480 
   C653.184082,92.775696 645.956909,97.940247 637.246460,97.979378 
   C621.590454,98.049721 605.933960,98.000702 590.277649,98.001526 
   C588.463623,98.001625 586.649597,98.001549 584.418213,98.001549 
   C584.418213,108.028717 584.418213,117.717056 584.418213,127.998596 
   C589.027222,127.998596 593.477966,127.998573 597.928711,127.998589 
   C612.085999,127.998634 626.243286,127.984550 640.400574,128.002808 
   C654.115356,128.020493 661.008362,136.591965 658.011536,149.860611 
   C656.641357,155.926712 650.308411,160.881912 642.718262,160.924698 
   C614.737732,161.082458 586.755310,161.072266 558.774475,160.940063 
   C550.835083,160.902542 543.085754,152.639496 543.058838,144.029419 
   C542.932129,103.390083 542.824646,62.748718 543.141968,22.111446 
   C543.214355,12.840591 548.241150,6.005494 559.193542,4.940597 
   C559.977417,4.864385 560.722412,4.389108 562.234436,4.319090 
   C564.132202,4.689221 565.280945,4.969551 566.429871,4.970876 
   C589.403442,4.997368 612.377014,5.006969 635.350464,4.956220 
   C636.797424,4.953023 638.243286,4.428169 639.689636,4.146003 
   C641.938049,4.526516 644.221313,4.766272 646.428955,5.311864 
   C656.087341,7.698852 659.391846,14.001287 658.887146,22.804539 
   C658.408508,31.153694 652.316895,36.458900 643.505432,37.045959 
   C642.860474,37.088928 642.230530,37.357796 640.848999,37.456711 
   C622.213074,37.302826 604.321777,37.202774 586.430359,37.163174 
   C585.669312,37.161491 584.907104,37.719879 584.145447,38.017349 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M1399.551758,203.269745 
   C1399.882935,243.683060 1399.905884,284.383484 1399.893799,325.550415 
   C1399.573242,324.782104 1399.039307,323.547546 1399.037964,322.312439 
   C1398.997681,283.385803 1399.007935,244.459122 1399.022095,205.532455 
   C1399.022339,204.873886 1399.166626,204.215393 1399.551758,203.269745 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M1268.551880,206.269623 
   C1268.883057,244.683014 1268.906006,283.383514 1268.893921,322.550537 
   C1268.573242,321.782257 1268.039307,320.547821 1268.037964,319.312805 
   C1267.997070,282.385956 1268.007812,245.459061 1268.022095,208.532181 
   C1268.022339,207.873672 1268.166626,207.215225 1268.551880,206.269623 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M639.245850,4.092392 
   C638.243286,4.428169 636.797424,4.953023 635.350464,4.956220 
   C612.377014,5.006969 589.403442,4.997368 566.429871,4.970876 
   C565.280945,4.969551 564.132202,4.689221 562.646973,4.342424 
   C587.807800,4.110291 613.304932,4.074535 639.245850,4.092392 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M643.551453,204.270233 
   C643.903198,225.279221 643.955505,246.575424 643.962952,267.871613 
   C643.963318,268.817139 643.554321,269.762787 643.078491,270.846680 
   C642.880615,251.000870 642.933472,231.016739 643.009827,211.032684 
   C643.018066,208.873993 643.162415,206.715836 643.551453,204.270233 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M1366.245972,309.092346 
   C1365.417603,309.425995 1364.146118,309.945984 1362.873169,309.949738 
   C1346.488525,309.998291 1330.103760,309.982391 1313.718994,309.943634 
   C1312.749634,309.941345 1311.781006,309.605164 1310.478516,309.202667 
   C1328.697388,309.000244 1347.249878,309.019501 1366.245972,309.092346 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M584.601929,38.011406 
   C584.907104,37.719879 585.669312,37.161491 586.430359,37.163174 
   C604.321777,37.202774 622.213074,37.302826 640.465637,37.559052 
   C622.237366,37.819366 603.647888,37.912411 584.601929,38.011406 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M1361.383789,246.174561 
   C1359.214966,246.517120 1356.598145,246.984177 1353.985107,246.963501 
   C1339.593506,246.849625 1325.202881,246.616531 1310.478394,246.202637 
   C1327.075684,246.011932 1344.006592,246.042908 1361.383789,246.174561 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M507.894043,129.524597 
   C507.555298,126.723450 507.062500,123.458725 507.039185,120.190651 
   C506.960052,109.099983 507.046967,98.008125 507.105286,86.450272 
   C507.426483,87.382904 507.955902,88.781059 507.961243,90.181221 
   C508.010712,103.140442 507.969788,116.100014 507.894043,129.524597 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M468.988525,101.601410 
   C469.182892,109.859879 469.383270,118.574860 469.417786,127.673073 
   C469.166168,119.390182 469.080353,110.724052 468.988525,101.601410 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M201.668594,151.291107 
   C212.949692,156.174454 223.900650,160.848770 236.198227,166.097885 
   C222.126816,193.761230 208.469818,221.414276 194.043961,248.660233 
   C185.747803,264.329071 176.105545,279.280975 167.298050,294.686432 
   C165.154358,298.436005 163.178391,299.440308 158.723709,298.197357 
   C145.189926,294.421173 132.686157,288.432678 120.532143,281.631104 
   C119.359528,280.974915 118.541580,278.052429 118.913254,276.507904 
   C128.184311,237.980698 140.339005,200.361694 154.847366,163.491531 
   C158.431290,154.383652 161.791992,145.187943 165.260040,136.020126 
   C177.561127,141.155563 189.449783,146.118805 201.668594,151.291107 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M124.707504,142.643982 
   C117.898956,164.552582 110.644608,185.946091 104.877258,207.733200 
   C100.280876,225.096786 97.498482,242.940567 93.690880,261.652100 
   C90.873199,259.009521 87.525612,256.666229 85.209137,253.555161 
   C76.667793,242.084015 68.433899,230.382599 60.195583,218.689453 
   C59.430519,217.603546 59.036983,215.919907 59.176678,214.587814 
   C59.999168,206.744690 60.676342,198.859802 62.068806,191.108704 
   C64.668159,176.639465 67.079628,162.084702 70.773254,147.878540 
   C74.383881,133.991623 79.642281,120.539993 83.858612,106.799454 
   C85.091019,102.783188 86.709267,102.468681 90.234200,104.009720 
   C102.796539,109.501747 115.454750,114.776260 128.112335,120.047462 
   C131.418076,121.424133 132.759995,122.847649 130.822006,126.726845 
   C128.352737,131.669495 126.832680,137.086319 124.707504,142.643982 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M284.280029,253.247986 
   C273.331543,267.536438 262.908936,281.786530 251.707047,295.395203 
   C249.231125,298.403107 244.142715,300.139862 240.007568,300.739288 
   C227.144928,302.603882 214.170319,303.727600 201.218903,304.918732 
   C198.904236,305.131622 196.493057,304.294952 193.348831,303.827240 
   C224.026962,265.474640 247.924713,224.023071 267.921417,179.567352 
   C283.132935,185.990707 298.282135,192.387756 314.052856,199.047256 
   C305.758820,218.061493 295.648834,235.790649 284.280029,253.247986 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M40.946327,129.838730 
   C41.289455,114.793983 44.851490,100.880516 49.519730,86.854538 
   C55.030991,89.249023 60.373528,91.570206 65.791092,93.923981 
   C55.480900,120.363632 48.314636,147.141876 44.287090,174.880585 
   C41.480461,160.167862 38.650795,145.455658 40.946327,129.838730 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M582.889160,257.157715 
   C583.094299,277.501007 583.053467,297.985168 582.989929,318.469208 
   C582.983765,320.461182 582.837219,322.452728 582.447998,324.730835 
   C582.096252,303.393585 582.044373,281.769958 582.035400,260.146362 
   C582.034973,259.197144 582.427368,258.247772 582.889160,257.157715 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M1441.017822,218.144897 
   C1454.187256,218.447968 1467.426636,216.593048 1480.496338,219.935745 
   C1488.514160,221.986389 1491.419312,226.982483 1491.936646,235.513855 
   C1492.389160,242.977905 1487.713257,249.276917 1480.663940,250.867661 
   C1477.174194,251.655182 1473.509644,251.668198 1469.009521,252.035706 
   C1459.221069,252.039780 1450.347168,252.039780 1441.017822,252.039780 
   C1441.017822,240.250488 1441.017822,229.197693 1441.017822,218.144897 
z"
    />
    <path
      fill="#007F7F"
      opacity="1.000000"
      stroke="none"
      d="
M1440.796021,218.479568 
   C1441.017822,229.197693 1441.017822,240.250488 1441.017822,252.039780 
   C1450.347168,252.039780 1459.221069,252.039780 1468.565430,252.093613 
   C1460.431152,252.393784 1451.826538,252.751404 1443.222168,252.741348 
   C1442.181885,252.740143 1440.251587,250.759460 1440.243774,249.685104 
   C1440.168335,239.396164 1440.417847,229.104858 1440.796021,218.479568 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M747.043396,33.995613 
   C756.915283,32.606544 765.639771,34.271290 773.498901,39.902870 
   C781.958374,45.964603 785.870239,54.738785 787.632507,64.497849 
   C789.973022,77.459854 789.983154,90.433586 786.054565,103.207428 
   C779.652466,124.023880 761.440002,132.062073 741.547241,126.565926 
   C727.282349,122.624657 721.163452,111.538948 718.002197,98.126823 
   C714.942505,85.145515 715.871338,72.327171 719.022644,59.517685 
   C722.372314,45.901917 733.940308,35.125881 747.043396,33.995613 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M433.242950,277.998169 
   C431.001953,277.998108 429.226532,277.998108 426.568665,277.998108 
   C433.408508,262.695648 439.903992,248.163651 446.827393,232.674347 
   C453.710114,247.971802 460.309540,262.639587 467.219757,277.998199 
   C455.585999,277.998199 444.647247,277.998199 433.242950,277.998169 
z"
    />
    <path
      fill="#000000"
      opacity="0.000000"
      stroke="none"
      d="
M791.076538,256.862305 
   C794.190063,263.763153 797.234131,270.287628 800.607056,277.516754 
   C786.830627,277.516754 773.874084,277.516754 760.253113,277.516754 
   C766.886353,262.720490 773.450684,248.077820 780.384338,232.611374 
   C784.199524,241.185898 787.603271,248.835892 791.076538,256.862305 
z"
    />
  </svg>
);

export default LogoSVG;
