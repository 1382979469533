/* eslint-disable react/no-danger-with-children */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React from "react";
import { Radio, Form, Row, Col } from "antd";

const TeamCard = ({ title, subTitle, text, img }) => {
  const onChange = (val) => {
    console.log(val);
  };
  return (
    <Row className="team-card">
      {/* represents a team card with a title, an image, a bold subtitle, and additional text information*/}
      <Col sm={24} md={24}>
        {" "}
        {/*so that this component occupies the full width on small/medium screens 
      since both small and medium is included here this makes it a full width component regardless of screen size */}
        <div className="team-card-title">{title}</div>
      </Col>
      <Col sm={24} md={24}>
        <Col sm={24} md={24}>
          <img src={img} alt="avatar" className="pull-left" />
        </Col>
        <Col sm={24} md={24}>
          <p className="bold">{subTitle}</p>
          <p> {text}</p>{" "}
        </Col>
      </Col>
    </Row>
  );
};
export default TeamCard;
