import React, { useEffect } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import "./App.css";
import OnlineTool from "./containers/OnlineTool";
import Home from "./containers/Home";
import Team from "./containers/Team";
import Methodology from "./containers/Methodology";
import ReactGA from "react-ga4";
import { iconEllipse } from "./components/icons";

const TRACKING_ID = "G-L9L97TKC9J"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
  let a = 3;
  if (a === 5) {
    a = 3;
  }

  return (
    <>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dashboard" element={<OnlineTool />} />
          <Route exact path="/Team" element={<Team />} />
          <Route exact path="/Methodology" element={<Methodology />} />
          <Route exact path="/ellipse-icon" element={<iconEllipse />} />
        </Routes>
      </HashRouter>
    </>
  );
};
//
export default App;
