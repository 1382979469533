import React, { useState, useEffect, useRef } from "react";
import mapData from "../data/zip3Names.json";

import L from "leaflet";
// import "leaflet/dist/leaflet.css";
import {
  GeoJSON,
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  Popup,
} from "react-leaflet";
import RawData from "../data/NewCervicalCancerData2.json";
import AllRawData from "../data/NewAllCervicalCancerData2.json";
import CenterData from "../data/zip5Names.json";
import MapStateHandler from "../components/MapStateHandler";
import { iconEllipse } from "../components/icons";
import { ReactComponent as Ellipse } from "../assets/img/ellipse-19.svg";
const Map = ({
  // year,
  ageGroup,
  // denominatorFilter,
  insuranceFilter,
  reportFilter,
  showCenters,
  rk,
}) => {
  const defaultMSAStyle = {
    fillColor: "gray",
    color: "gray",
    fillOpacity: 0,
    weight: 1,
  };
  // const markerStyle = {
  //   fillColor: "rgb(32,96,177)",
  //   color: "rgb(32,96,177)",
  //   fillOpacity: 100,
  //   weight: 1,
  // };
  const [center, setCenter] = useState({
    lat: 38,
    lng: -95,
  });

  let defaultZoomLevel = 5;
  const isMobile = window.innerWidth <= 500;
  if (isMobile) {
    defaultZoomLevel = 3;
  }

  const [zoom, setZoom] = useState(defaultZoomLevel);
  const [index, setIndex] = useState(1);
  // const [a, setYear] = useState("");
  const [b, setAgeGroup] = useState("");
  // const [c, setDenominatorFilter] = useState(0);
  const [d, setInsuranceFilter] = useState("");
  const [e, setReportFilter] = useState("");
  const [checked0, setChecked0] = useState(true);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [checked3, setChecked3] = useState(true);
  const [checked4, setChecked4] = useState(true);
  // const [checkedGray, setCheckedGray] = useState(false);
  const rkComp = useRef(rk);
  useEffect(() => {
    window.scrollTo(1000, 10000);
  }, []);

  let legend0 = "ㅤBurden  : 0% - 15%ㅤ";
  let legend1 = "ㅤBurden  : 15% - 20%ㅤ";
  let legend2 = "ㅤBurden  : 20% - 25%ㅤ";
  let legend3 = "ㅤBurden  : 25% - 30%ㅤ";
  let legend4 = "ㅤBurden  : 30% - 100%ㅤ";
  // let legendGray = "ㅤFiltered out with min # of cases";
  // let legend7 = "ㅤOverlaying territory-zipcode pairs";

  if (reportFilter === "CC Rates") {
    legend0 = "ㅤBurden  : 0 - 75ㅤ";
    legend1 = "ㅤBurden  : 75 - 100ㅤ";
    legend2 = "ㅤBurden  : 100 - 125ㅤ";
    legend3 = "ㅤBurden  : 125 - 150ㅤ";
    legend4 = "ㅤBurden  : 150 - 300ㅤ";
  } else if (reportFilter === "Screen Rate") {
    legend0 = "ㅤRate : 0% - 45%ㅤ";
    legend1 = "ㅤRate : 45% - 50%ㅤ";
    legend2 = "ㅤRate : 50% - 55%ㅤ";
    legend3 = "ㅤRate : 55% - 60%ㅤ";
    legend4 = "ㅤRate : 60% - 100%ㅤ";
  } else if (reportFilter === "CC Cases") {
    legend0 = "ㅤ# of cases : 4 - 5ㅤ";
    legend1 = "ㅤ# of cases : 6 - 15ㅤ";
    legend2 = "ㅤ# of cases : 16 - 30ㅤ";
    legend3 = "ㅤ# of cases : 31 - 50ㅤ";
    legend4 = "ㅤ# of cases : 50 - 1000ㅤ";
  } else if (reportFilter === "r/mCC Cases") {
    legend0 = "ㅤ# of cases : 4 - 5ㅤ";
    legend1 = "ㅤ# of cases : 6 - 7ㅤ";
    legend2 = "ㅤ# of cases : 8 - 10ㅤ";
    legend3 = "ㅤ# of cases : 11 - 20ㅤ";
    legend4 = "ㅤ# of cases : 21 - 225ㅤ";
  } else if (reportFilter === "Poverty") {
    legend0 = "ㅤRate : 0% - 18%ㅤ";
    legend1 = "ㅤRate : 18% - 22%ㅤ";
    legend2 = "ㅤRate : 22% - 26%ㅤ";
    legend3 = "ㅤRate : 26% - 30%ㅤ";
    legend4 = "ㅤRate : 30% - 100%ㅤ";
  }
  if (
    // a !== year ||
    b !== ageGroup ||
    // c !== denominatorFilter ||
    d !== insuranceFilter ||
    e !== reportFilter
  ) {
    setIndex(Math.random());
    // setYear(year);
    setAgeGroup(ageGroup);
    // setDenominatorFilter(denominatorFilter);
    setInsuranceFilter(insuranceFilter);
    setReportFilter(reportFilter);
  }
  let resultData = RawData;
  if (reportFilter === "CC Rates" || reportFilter === "CC Cases") {
    resultData = AllRawData;
  }

  let dataUse = [];
  for (const key in resultData) {
    let insuranceFilterUse = insuranceFilter;
    // if (insuranceFilter === "Medicaid") {
    //   insuranceFilterUse = "Medicaid";
    // } else if (insuranceFilter === "Privately Managed") {
    //   insuranceFilterUse = "Privately Managed";
    // }
    if (
      // resultData[key].Year === year &&
      resultData[key].AgeGroup === ageGroup &&
      resultData[key].Insurance === insuranceFilterUse
    ) {
      dataUse.push(resultData[key]);
    }
  }
  // const centerPopup = (MSA) => {
  //   const MSAID = MSA.properties.zip3;
  //   const MSAName = MSA.properties.name;
  //   return "<div>Center: <div class='strong-text'>" + MSAID + "<div/> <div/>";
  // };
  const getPopUpData = (ID) => {
    let returnValue = "No data!";
    let num = 0;
    let denom = 0;
    let numScreened = 0;
    let numEnrolleesScreened = 0;
    let name = "";
    for (const key in dataUse) {
      if (
        (reportFilter === "r/mCC Cases" || reportFilter === "CC Cases") &&
        dataUse[key].Zip3 === ID
      ) {
        num = num + dataUse[key].Numerator;
        // denom = 1;
      } else if (reportFilter === "Screen Rate" && dataUse[key].Zip3 === ID) {
        num = num + dataUse[key].ScreenRate;
        numScreened = dataUse[key].Screened;
        numEnrolleesScreened = dataUse[key].EnrolleesScreen;
      } else if (reportFilter === "Poverty" && dataUse[key].Zip3 === ID) {
        num = num + dataUse[key].Pov;
        denom = dataUse[key].Total;
      } else if (dataUse[key].Zip3 === ID) {
        num = num + dataUse[key].Numerator;
        denom = denom + dataUse[key].Denominator;
        name = dataUse[key].Description;
      }
    }
    // thousands separator for numerator and denominator
    if (reportFilter === "CC Cases") {
      returnValue = parseFloat(num).toFixed(0);
      return (
        "<div># of diagnosed CC cases: <div class='strong-text'>" +
        returnValue +
        "<div/> <div/>"
      );
    }
    if (reportFilter === "r/mCC Cases") {
      returnValue = parseFloat(num).toFixed(0);
      return (
        "<div># of diagnosed r/mCC cases: <div class='strong-text'> " +
        returnValue +
        " <div/> <div/>"
      );
    }
    if (reportFilter === "Screen Rate") {
      returnValue = parseFloat(num).toFixed(1);
      numScreened = numScreened
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      numEnrolleesScreened = numEnrolleesScreened
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        // "<div class='strong-text'> " +
        // returnValue +
        // "% (" +
        // numScreened +
        // " out of " +
        // numEnrolleesScreened +
        // ") <div/>" +
        // "screening-eligible enrollees are screened for CC."
        "<div>" +
        "<b>" +
        returnValue +
        "%  (" +
        numScreened +
        " out of " +
        numEnrolleesScreened +
        ")</b> screening-eligible enrollees are screened for CC."
      );
    }

    returnValue = parseFloat((num / denom) * 100).toFixed(1) + "%";

    if (denom === 0) {
      returnValue = "No data!";
      return "<div>" + returnValue + "<b/><h3/><div/>";
    } else if (reportFilter === "CC Rates") {
      returnValue = parseFloat((num / denom) * 100000).toFixed(1);
    }
    num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    denom = denom.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //38.5% (5 out of 13) cervical cancer cases are classified as recurrent or metastatic cervical cancer.

    if (reportFilter === "r/mCC Rates") {
      return (
        "<div>" +
        "<b>" +
        returnValue +
        "    (" +
        num +
        " out of " +
        denom +
        ")</b> cervical cancer cases are classified as recurrent or metastatic cervical cancer."
      );
    } else if (reportFilter === "CC Rates") {
      return (
        "<div>" +
        "<b>" +
        num +
        "</b>" +
        " CC cases are diagnosed among " +
        "<b>" +
        denom +
        "</b>" +
        " eligible female enrollees. The prevalent number of CC diagnoses per 100,000 eligible female enrollees is:  <h3><b>" +
        returnValue +
        "<b/><h3/><div/>"
      );
    } else if (reportFilter === "Poverty") {
      num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      denom = denom.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        "<div>" +
        "<b>" +
        returnValue +
        " (" +
        num +
        " out of " +
        denom +
        ")</b> families with an income to poverty level ≤ 200%."
      );
    }
    //
  };
  let legendSteps = [0.15, 0.2, 0.25, 0.3, 100.1];
  if (reportFilter === "r/mCC Cases") {
    legendSteps = [5, 7, 10, 20, 100000.1];
  }
  if (reportFilter === "CC Cases") {
    legendSteps = [5, 15, 30, 50, 100000.1];
  }
  if (reportFilter === "CC Rates") {
    legendSteps = [75, 100, 125, 150, 300];
  }
  if (reportFilter === "Screen Rate") {
    legendSteps = [45, 50, 55, 60, 100];
  }
  if (reportFilter === "Poverty") {
    legendSteps = [18, 22, 26, 30, 100.1];
  }
  const setColorGrade = (layer, ID) => {
    let val = 0;
    let num = 0;
    let denom = 0;
    const opacity = 1;
    for (const key in dataUse) {
      if (dataUse[key].Zip3 === ID) {
        if (reportFilter === "Screen Rate") {
          num = dataUse[key].ScreenRate;
        } else if (reportFilter === "Poverty") {
          num = dataUse[key].Poverty;
        } else {
          num = num + dataUse[key].Numerator;
          denom = denom + dataUse[key].Denominator;
        }
      }
    }
    layer.options.color = "gray";
    layer.options.weight = 1;

    if (reportFilter === "r/mCC Cases" || reportFilter === "CC Cases") {
      val = num;
    } else if (reportFilter === "Screen Rate" || reportFilter === "Poverty") {
      val = num;
      denom = 1;
    } else {
      val = num / denom;
    }
    if (reportFilter === "CC Rates") {
      val = val * 100000;
    }

    if (denom === 0 || val === 0) {
      layer.options.fillOpacity = 0;
    }
    //    else if (denominatorFilter > denom && reportFilter === "r/mCC Rates") {
    //   //   // layer.options.fillOpacity = 0.3;
    //   layer.options.fillColor = "gray";
    //     layer.options.color = "gray";
    // }
    else if (val <= legendSteps[0]) {
      layer.options.fillOpacity = opacity;
      layer.options.fillColor = "#85aca2";
    } else if (val <= legendSteps[1]) {
      layer.options.fillOpacity = opacity;
      layer.options.fillColor = "#d4e2b7";
    } else if (val <= legendSteps[2]) {
      layer.options.fillOpacity = opacity;
      layer.options.fillColor = "#f6dea6";
    } else if (val <= legendSteps[3]) {
      layer.options.fillOpacity = opacity;
      layer.options.fillColor = "#db9368";
    } else if (val <= legendSteps[4]) {
      layer.options.fillOpacity = opacity;
      layer.options.fillColor = "#a63a36";
    }

    layer.options.color = "gray";
    layer.options.weight = 1;
    if (1 === 2) {
      for (const key in dataUse) {
        if (dataUse[key].Zip3 === ID) {
          layer.options.color = "blue";
          // make it shaded
          layer.options.weight = 2;
        }
      }
    }
  };
  const onEachMSA = (MSA, layer, def) => {
    const MSAID = MSA.properties.zip3;
    const MSAName = MSA.properties.name;

    if (def) {
      return;
    }

    setColorGrade(layer, MSAID);

    const popUp = () => {
      layer
        .bindPopup(
          "<b> " +
            MSAName +
            " (ZIP-3: " +
            MSAID +
            ")" +
            " </b>" +
            getPopUpData(MSAID)
        )
        .openPopup();
    };
    layer.on({
      mouseover: popUp,
    });
  };
  const onEachCenter = (centerPoint, layer) => {
    const zip5Code = centerPoint.properties.zip5;
    const zip5Name = centerPoint.properties.name;
    const popUp = () => {
      layer
        .bindPopup("<b> " + zip5Name + " (ZIP-5: " + zip5Code + ")" + " </b>")
        .openPopup();
    };
    layer.on({
      mouseover: popUp,
    });
  };

  const filterFeatures = (geoJsonFeature, minValue, maxValue) => {
    let val = 0;
    let dataUseToFilter = dataUse;
    // it is used to draw borders for all MSAs
    if (maxValue === 987654321) {
      dataUseToFilter = resultData;
    }
    // if (reportFilter === "CC Rates") {
    //   minValue = minValue / 1000;
    //   maxValue = maxValue / 1000;
    // }
    ////////////////////////////////////////////////////
    //if (geoJsonFeature.properties.lsad === "M1") {
    //////////////////////////////////////////
    let num = 0;
    let denom = 0;
    for (const key in dataUseToFilter) {
      if (dataUseToFilter[key].Zip3 === geoJsonFeature.properties.zip3) {
        if (reportFilter === "Screen Rate") {
          num = dataUseToFilter[key].ScreenRate;
        } else if (reportFilter === "Poverty") {
          num = dataUseToFilter[key].Poverty;
        } else {
          num = num + dataUseToFilter[key].Numerator;
        }
        denom = denom + dataUseToFilter[key].Denominator;
      }
    }
    if (
      reportFilter === "CC Cases" ||
      reportFilter === "r/mCC Cases" ||
      reportFilter === "Screen Rate" ||
      reportFilter === "Poverty"
    ) {
      val = num;
    } else if (reportFilter === "CC Rates") {
      val = (num * 100000) / denom;
    } else {
      val = num / denom;
    }
    if (val > minValue && val <= maxValue && val > 0) {
      console.log("true");

      return true;
    }
    // }
    else {
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(rkComp.current, rkComp.current);
    rkComp.current++;
  });
  // const options = {
  //   pointToLayer: (feature, latLng) => L.marker(latLng, { icon: chargerBlue })
  // }

  if (rkComp.current === 0) {
    rkComp.current = 10000;
  }
  var geojsonMarkerOptions = {
    radius: 3,
    fillColor: "#0049A6",
    color: "#0049A6",
    weight: 1,
    opacity: 8,
    fillOpacity: 0.5,
  };

  return (
    <div>
      <MapContainer
        center={center}
        zoom={zoom}
        scrollWheelZoom={true}
        key={index}
      >
        <MapStateHandler setZoom={setZoom} setCenter={setCenter} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />

        {/* <Legend /> */}
        <GeoJSON
          index={index}
          style={defaultMSAStyle}
          data={mapData.features}
          onEachFeature={(MSA, layer) => onEachMSA(MSA, layer, true)}
          filter={(geoJsonFeature) =>
            filterFeatures(geoJsonFeature, 0, 987654321)
          }
        />

        {/* {showCenters ? (
          CenterData.map((data, index) => {
            return (
              <Marker position={data.geometry.coordinates} icon={iconEllipse}>
                {" "}
                <Popup>
                  {" "}
                  {data.properties.name} (ZIP5: {data.properties.zip5})
                </Popup>
              </Marker>
            );
          })
        ) : (
          <div></div>
        )} */}
        <LayersControl collapsed={false} position="bottomright">
          <LayersControl.Overlay checked={checked0} name={legend0}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, 0, legendSteps[0])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked0(true);
                },
                remove: (e) => {
                  setChecked0(false);
                },
              }}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={checked1} name={legend1}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, legendSteps[0], legendSteps[1])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked1(true);
                },
                remove: (e) => {
                  setChecked1(false);
                },
              }}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={checked2} name={legend2}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, legendSteps[1], legendSteps[2])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked2(true);
                },
                remove: (e) => {
                  setChecked2(false);
                },
              }}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={checked3} name={legend3}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, legendSteps[2], legendSteps[3])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked3(true);
                },
                remove: (e) => {
                  setChecked3(false);
                },
              }}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={checked4} name={legend4}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, legendSteps[3], legendSteps[4])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked4(true);
                },
                remove: (e) => {
                  setChecked4(false);
                },
              }}
            />
          </LayersControl.Overlay>
          {showCenters ? (
            <GeoJSON
              index={index}
              data={CenterData}
              icon={iconEllipse}
              onEachFeature={onEachCenter}
              pointToLayer={(feature, latlng) => {
                return L.circleMarker(latlng, geojsonMarkerOptions);
              }}
            />
          ) : (
            <div></div>
          )}
          {/*
          <LayersControl.Overlay checked={checked5} name={legend5}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, legendSteps[4], legendSteps[5])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked5(true);
                },
                remove: (e) => {
                  setChecked5(false);
                },
              }}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={checked6} name={legend6}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) =>
                filterFeatures(geoJsonFeature, legendSteps[5], legendSteps[6])
              }
              eventHandlers={{
                add: (e) => {
                  setChecked6(true);
                },
                remove: (e) => {
                  setChecked6(false);
                },
              }}
            />
          </LayersControl.Overlay>
          */}
          {/*
          <LayersControl.Overlay checked={checkedGray} name={legend7}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) => filterFeatures(geoJsonFeature, 0, 0)}
            />
          </LayersControl.Overlay>
*/}
          {/* <LayersControl.Overlay checked={checkedGray} name={legendGray}>
            <GeoJSON
              index={index}
              data={mapData.features}
              onEachFeature={onEachMSA}
              filter={(geoJsonFeature) => filterFeatures(geoJsonFeature, 0, 0)}
            />
          </LayersControl.Overlay> */}
        </LayersControl>
      </MapContainer>
    </div>
  );
};

export default Map;

// const changeMSAColor = (event) => {
//   event.target.setStyle({
//     color: "green",
//     fillColor: "black",
//     fillOpacity: 1,
//   });
// };
