/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React from "react";
import { Radio, Form } from "antd";

const FilterRadio = ({
  title,
  defaultValue,
  options,
  setValue,
  visible,
  type,
}) => {
  // takes a text parameter and returns a substring of the text up to the first occurrence of the "|" (pipe) character.
  const trimText = (text) => {
    const index = text.indexOf("|");
    if (index !== -1) {
      return text.substring(0, index);
    }
    return text;
  };

  const onChange = (val) => {
    setValue(val.target.value);
  };

  const styleRadio = {};
  // activate here if they do not want to show the filter completely.
  // if (visible == false) {
  //   styleRadio.display = "none";
  // }

  return (
    <div className="filters" style={styleRadio}>
      {" "}
      {/*if there was anything inside style radio this is where it would show up*/}
      <p className="strong-text">{trimText(title)}</p>{" "}
      {/*if the title had | in it trimText is used to get rid of it*/}
      <Radio.Group
        options={options} //radio button option prob array
        onChange={onChange} //calls the onChange above to set value
        disabled={visible === false} //disabled is true when visible is set to false, so that if the radio button group is not visible it also becomes disabled
        defaultValue={defaultValue}
        optionType={type}
      />
    </div>
  );
};
export default FilterRadio;
