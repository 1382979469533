import React from "react";
import backgroundImg from "../assets/img/bg-20220214-5.png";
import { BackTop, Row, Col } from "antd";
import "../assets/css/bootstrap.min.css";
import "../App.css";
import Footer from "./Footer";
import HeaderNew from "./HeaderNew";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const navigateTool = () => {
    navigate("/dashboard");
    // console.log("asdads");
  };

  const returnValue = (
    <>
      <HeaderNew selected="home" />
      <div>
        <div id="site-overlay" />
        {/* start header  */}
        <header className="site-header">
          {/* start desktop menu  */}
          <div className="header-top d-none d-lg-block">
            <div className="container">
              <div className="jsx-1674335436 headerx" />
            </div>
          </div>
          {/* End desktop menu  */}
          {/* start mobile menu  */}
          <div className="header-block d-block d-lg-none">
            <div className="container">
              <div className="row align-items-center">
                <div className="jsx-1674335436 headerx" />
              </div>
            </div>
          </div>
          {/* End mobile menu  */}
        </header>
        {/* End header  */}
        <section
          className="hero-banner background-contain min-vh-80 d-flex align-items-center justify-content-center w-100"
          style={{ backgroundImage: `url(${backgroundImg})` }}
        >
          <div className="hero-inner">
            <div className="hero-content">
              <div className="container">
                <div className="col-sm-12 col-lg-8">
                  <h1 id="main-title-header">Cervical Cancer Geo-Analyzer</h1>
                </div>
                <div className="col-sm-12 col-lg-8">
                  <p
                    onClick={navigateTool}
                    className="btn btn-bg btn-radius simulator-button"
                  >
                    Go to online tool
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="content-container">
          <Row justify="start">
            <Col md={24}>
              <div className="team-header-wrapper">Cervical cancer</div>
            </Col>
          </Row>
          <Row justify="start">
            <Col md={24}>
              <Row justify="start">
                <p>
                  Despite immunization and screening availability, cervical
                  cancer is still the fourth most frequently diagnosed and
                  fourth most deadly cancer in women worldwide.<sup>1</sup> In
                  the US, an estimated 13,960 new cases of cervical cancer were
                  diagnosed in 2023, and an estimated 4,310 women died from the
                  disease.<sup>2 </sup>
                  <br />
                  <br />
                  Cervical cancer affects more young women disproportionately,
                  with the median age at diagnosis of 50 years, approximately 10
                  years younger that of other women's cancers, such as ovarian,
                  breast, and endometrial cancers.
                  <sup>2-5</sup> In the US, it is the third most frequently
                  diagnosed cancer among women aged 35-44.
                  <sup>6</sup> Thus, not only does cervical cancer continue to
                  pose a significant medical challenge in the US, it can also
                  have a negative impact on family social structure and economic
                  wellbeing.<sup>7</sup>
                  <br />
                </p>
              </Row>
            </Col>
          </Row>

          <Row justify="start">
            <Col md={24}>
              <div className="team-header-wrapper">
                Recurrent or metastatic cervical cancer
              </div>
            </Col>
          </Row>
          <Row justify="start">
            <Col md={24}>
              <Row justify="start">
                <p>
                  Recurrent or metastatic cervical cancer (r/mCC) is a
                  devastating condition and is incurable. Among women with
                  cervical cancer, up to 15% initially present with metastatic
                  disease (Stage IV). The 5-year survival rate for metastatic
                  cervical cancer in the US is only 19%, compared with 91% for
                  localized cervical cancer.<sup>2</sup> Even among women
                  presenting with an earlier stage at diagnosis, up to 61% will
                  recur with metastatic disease within the first 2 years
                  following completion of therapy.<sup>8</sup>
                </p>
                <p>
                  For additional information, please see the related article
                  published in Gynecologic Oncology Reports{" "}
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S2352578922002016?via%3Dihub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </Row>
            </Col>
          </Row>

          <Row justify="start">
            <Col md={24}>
              <div className="team-header-wrapper">
                Cervical Cancer Geo-Analyzer
              </div>
            </Col>
          </Row>
          <Row justify="start">
            <Col md={24}>
              <Row justify="start">
                <p>
                  The Cervical Cancer Geo-Analyzer is an open access, web-based,
                  interactive tool to visualize cervical cancer and r/mCC burden
                  across different communities in the United States. The tool
                  aims to help researchers, policy makers, and advocacy groups
                  inform geographical areas in the United States where cervical
                  cancer education or healthcare resource needs are high. For
                  building the user interface, we utilized free and open-source
                  ReactJS software.
                </p>
                <p>
                  For additional information, please see the related article
                  published in Gynecologic Oncology{" "}
                  <a
                    href="https://www.gynecologiconcology-online.net/article/S0090-8258(22)01983-7/fulltext"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </Row>
            </Col>
          </Row>
          <Row justify="start">
            <Col md={24}>
              <div className="team-header-wrapper">Team</div>
            </Col>
          </Row>
          <Row justify="start">
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team1`} className="team-summary-title">
                  Tara Castellano, MD
                </Link>

                <p>
                  Assistant Professor<br />Department of Obstetrics and Gynecology<br />
                  Louisiana State University Health Sciences Center
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team2`} className="team-summary-title">
                  Turgay Ayer, PhD
                </Link>
                <p>
                  Virginia C. and Joseph C. Mello Chaired Professor<br />Department
                  of Industrial and Systems Engineering<br />Georgia Institute of
                  Technology
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team3`} className="team-summary-title">
                  Kathleen Moore, MD, MS
                </Link>
                <p>
                  Professor<br />Department of Obstetrics and Gynecology<br />
                  Stephenson Cancer Center
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team4`} className="team-summary-title">
                  Jag Chhatwal, PhD
                </Link>
                <p>
                  Associate Professor<br />Harvard Medical School<br /><br />Director<br />Massachusetts General Hospital (MGH)
                  Institute for Technology Assessment
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team5`} className="team-summary-title">
                  Christina Washington, MD
                </Link>
                <p>
                  Assistant Professor<br />Department of Obstetrics and Gynecology<br />
                  Stephenson Cancer Center
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team14`} className="team-summary-title">
                  Fernanda Musa, MD
                </Link>
                <p>Gynecologic Oncologist<br />Swedish Cancer Institute </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team15`} className="team-summary-title">
                  Charles A. Leath, III, MD, MSPH{" "}
                </Link>
                <p>
                  Professor<br />Department of Obstetrics and Gynecology<br />University of
                  Alabama at Birmingham
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team16`} className="team-summary-title">
                  Leslie Randall, MD, MAS{" "}
                </Link>
                <p>
                  Professor<br />Department of Obstetrics &
                  Gynecology<br />Virginia Commonwealth University
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team65`} className="team-summary-title">
                  Jie Ting, PhD, MSPH
                </Link>
                <p>
                  HTA, Value & Evidence Oncology<br />Pfizer Inc.
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team13`} className="team-summary-title">
                  Yitong (Jenny) Zhang, PhD
                </Link>
                <p>Global Medical Strategy Oncology<br />Pfizer Inc.</p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team8`} className="team-summary-title">
                  Asim Altunbas, MBA
                </Link>
                <p>Director of Operations<br />Value Analytics Labs</p>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team10`} className="team-summary-title">
                  Andrew ElHabr, PhD
                </Link>
                <p>Data Scientist<br />Value Analytics Labs</p>
              </div>
            </Col>{" "}
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team11`} className="team-summary-title">
                  Ezgi Berksoy, MS
                </Link>
                <p>Data Scientist<br />Value Analytics Labs</p>
              </div>
            </Col>{" "}
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team12`} className="team-summary-title">
                  Deniz Balta, BS
                </Link>
                <p>Data Scientist & UI Developer<br />Value Analytics Labs</p>
              </div>
            </Col>{" "}
            <Col md={24}>
              <div className="team-header-wrapper">Past Contributors</div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team6`} className="team-summary-title">
                  Kalyani Sonawane, PhD
                </Link>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team7`} className="team-summary-title">
                  Andy Surinach, MPH
                </Link>
              </div>
            </Col>
            <Col md={8}>
              <div className="team-summary-card">
                <Link to={`/Team#team9`} className="team-summary-title">
                  Yasin Yildiz, MS
                </Link>
              </div>
            </Col>{" "}
          </Row>
          <Row justify="start">
            <Col md={24}>
              <span className="ref-heading">References</span>
            </Col>
          </Row>
          <br />
          <Row justify="start">
            <Col md={24}>
              <p className="ref-text">
                <ol>
                  <li>
                    Bray F, Ferlay J, Soerjomataram I, Siegel RL, Torre LA,
                    Jemal A. Global cancer statistics 2018: GLOBOCAN estimates
                    of incidence and mortality worldwide for 36 <br></br>cancers
                    in 185 countries. CA: a cancer journal for clinicians 2018;
                    68(6): 394-424.
                  </li>
                  <li>
                    Insititute NC. SEER Cancer Stat Facts: Cervical Cancer.
                    2023.{" "}
                    <a href="https://seer.cancer.gov/statfacts/html/cervix.html">
                      https://seer.cancer.gov/statfacts/html/cervix.html
                    </a>{" "}
                    (accessed February 2024).
                  </li>
                  <li>
                    Insititute NC. SEER Cancer Stat Facts: Female Breast Cancer.
                    2023.{" "}
                    <a href="https://seer.cancer.gov/statfacts/html/breast.html">
                      https://seer.cancer.gov/statfacts/html/breast.html
                    </a>{" "}
                    (accessed February 2024).
                  </li>
                  <li>
                    Insititute NC. SEER Cancer Stat Facts: Uterine Cancer. 2023.{" "}
                    <a href="https://seer.cancer.gov/statfacts/html/corp.html">
                      https://seer.cancer.gov/statfacts/html/corp.html
                    </a>{" "}
                    (accessed February 2024).
                  </li>
                  <li>
                    Insititute NC. SEER Cancer Stat Facts: Ovarian Cancer. 2023.{" "}
                    <a href="https://seer.cancer.gov/statfacts/html/ovary.html">
                      https://seer.cancer.gov/statfacts/html/ovary.html
                    </a>{" "}
                    (accessed February 2024).
                  </li>
                  <li>
                    National Program of Cancer Registries and Surveillance,
                    Epidemiology and End Results Program SEER*Stat Database:
                    NPCR and SEER Incidence - U.S. Cancer <br></br>Statistics
                    Public Use Research Database, 2021 Submission (2001-2019).
                    United States Department of Health and Human Services,
                    Centers for Disease Control and<br></br> Prevention and
                    National Cancer Institute. Released June 2022. Accessed at
                    <a href=" https://www.cdc.gov/cancer/uscs/public-use/">
                      https://www.cdc.gov/cancer/uscs/public-use/.
                    </a>{" "}
                  </li>
                  <li>
                    Wittet S, Tsu V. Cervical cancer prevention and the
                    Millennium Development Goals. Bull World Health Organ 2008;
                    86(6): 488-90.
                  </li>

                  <li>
                    Pfaendler KS, Tewari KS. Changing paradigms in the systemic
                    treatment of advanced cervical cancer. Am J Obstet Gynecol
                    2016; 214(1): 22-30.
                  </li>
                </ol>

                <br />
                <br />
                <br />
              </p>
            </Col>
          </Row>
        </div>

        <BackTop />
      </div>
      <Footer />
    </>
  );

  return returnValue;
};

export default Home;
